<template>
    <div>
        <el-form  :model='formItem' ref='formItem'>
            <el-tabs v-model="activeName" @tab-click="handleClick">

                <el-tab-pane label="猎鹰计划优秀案例（标题）" name="topTitle">
                 
                    <el-form-item v-for="(item,index) in formItem.topTitle" :key="index" :label="item.name" :label-width="formLabelWidth" >
                        <el-input v-model="item.content" :maxlength="maxlength" show-word-limit placeholder='请输入标题' class='input-width' ></el-input>
                    </el-form-item>

			    </el-tab-pane>

                <el-tab-pane label="猎鹰计划优秀案例（描述）" name="topDetail">	

                    <el-form-item v-for="(item,index) in formItem.topDetail" :key="index" :label="item.name" :label-width="formLabelWidth" >
                        <el-input v-model="item.content" type="textarea" :autosize="{ minRows: 2, maxRows: 4}" :maxlength="maxlengthTextarea" show-word-limit placeholder='请输入描述' class='input-width'  ></el-input>
                    </el-form-item>

                </el-tab-pane>

                <el-tab-pane label="猎鹰计划改造记录（标题）" name="recordTitle">	
                    <el-form-item v-for="(item,index) in formItem.recordTitle" :key="index" :label="item.name" :label-width="formLabelWidth" >
                        <el-input v-model="item.content" :maxlength="maxlength" show-word-limit  placeholder='请输入标题' class='input-width' ></el-input>
                    </el-form-item>
                </el-tab-pane>



            </el-tabs>

        </el-form>   
        <div  slot="footer" class='dialog-footer'>
            <el-button   type='primary' :loading="loading" @click='onSubmit'>确 定</el-button>
            <el-button   @click='closeDialog()'>取 消</el-button>     
        </div>

       
    </div>
</template>
<script>
 
 
 

export default {
    components: {
    },
    props: {
        langs: {
            type: Array,
            default: () => {
                return [];
            }
        } 
    },
    filters: {
    },
    data() {
        return {
            maxlength: '200',
            maxlengthTextarea: '1000',
            formLabelWidth: '120px',
            loading: false,
            activeName: 'topTitle',
            formItem:{
                topTitle:[],
                topDetail:[],
                recordTitle:[]
            }
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init(){
            this.langs.forEach(element => {
                let item = {
                    code: element.code,
                    name: element.name,
                    content: '',
                    lang:element.code
                }
                this.formItem.topTitle.push(JSON.parse(JSON.stringify(item)))
                this.formItem.topDetail.push(JSON.parse(JSON.stringify(item)))
                this.formItem.recordTitle.push(JSON.parse(JSON.stringify(item)))
            });

            this.$store.dispatch('getConfigLang', {}).then((res) => {
                if(res!=null && res.topTitle!=null && res.topTitle.length>0){
                    this.formItem.topTitle.forEach(item=>{
                        res.topTitle.forEach(item2 => {
                            if(item.lang == item2.lang){
                                item.content = item2.content
                            }
                        });
                    });
                }
                if(res!=null && res.topDetail!=null && res.topDetail.length>0){
                    this.formItem.topDetail.forEach(item=>{
                        res.topDetail.forEach(item2 => {
                            if(item.lang == item2.lang){
                                item.content = item2.content
                            }
                        });
                    });
                }
                if(res!=null && res.recordTitle!=null && res.recordTitle.length>0){
                    this.formItem.recordTitle.forEach(item=>{
                        res.recordTitle.forEach(item2 => {
                            if(item.lang == item2.lang){
                                item.content = item2.content
                            }
                        });
                    });
                }
               
            }) 
        },
        handleClick(val){
          
        },
        onSubmit() {
            console.log(this.formItem)       
            this.$confirm( '是否提交数据', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.loading=true                        
                
                this.$store.dispatch('putConfigLang', this.formItem).then((res) => {
                    this.$message({
                        message: '提交成功',
                        type: 'success',
                        duration: 1000
                    });
                    this.closeDialog();
                }).finally(() => {
                    this.loading = false;
                });
                
            }).catch(() => {
            });
        },
 
        closeDialog() {
            this.$emit('closeConfigLangDialog');
        },

 
    }
};
</script>
<style scoped>

</style>
