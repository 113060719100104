var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "formItem", attrs: { model: _vm.formItem, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "recordDate",
                label: "记录日期",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  placeholder: "请选择记录日期",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd"
                },
                model: {
                  value: _vm.formItem.recordDate,
                  callback: function($$v) {
                    _vm.$set(_vm.formItem, "recordDate", $$v)
                  },
                  expression: "formItem.recordDate"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "must",
              attrs: { label: "上传图片", "label-width": _vm.formLabelWidth }
            },
            [
              _c("multipleImgUploadButton", {
                ref: "multipleImgUpload",
                attrs: {
                  imgMessage: _vm.imgMessage,
                  imgSize: 3,
                  imglimit: 9,
                  imgUrlList: _vm.formItem.imageList
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "启用状态",
                prop: "status",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949"
                },
                model: {
                  value: _vm.formItem.status,
                  callback: function($$v) {
                    _vm.$set(_vm.formItem, "status", $$v)
                  },
                  expression: "formItem.status"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "优秀案例",
                prop: "top",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949"
                },
                on: {
                  change: function($event) {
                    return _vm.changeTop()
                  }
                },
                model: {
                  value: _vm.formItem.top,
                  callback: function($$v) {
                    _vm.$set(_vm.formItem, "top", $$v)
                  },
                  expression: "formItem.top"
                }
              })
            ],
            1
          ),
          _vm.coverVisible
            ? _c(
                "el-form-item",
                {
                  staticClass: "must",
                  attrs: {
                    label: "上传封面",
                    "label-width": _vm.formLabelWidth
                  }
                },
                [
                  _c("div", [
                    _vm._v("（仅限1张，推荐分辨率 1600*952，单张≤3MB）")
                  ]),
                  _c("imgUpload", {
                    ref: "imageUpload",
                    attrs: { imgSize: 3, img: _vm.formItem.cover },
                    on: { event: _vm.changeUrl }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "多语言配置" } },
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                _vm._l(_vm.formItem.falconPlanLangs, function(item, index) {
                  return _c(
                    "el-tab-pane",
                    {
                      key: index,
                      attrs: { label: item.name, name: index + "" }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "记录标题",
                            prop: "falconPlanLangs." + index + ".title",
                            "label-width": _vm.formLabelWidth,
                            rules: _vm.rules.title
                          }
                        },
                        [
                          _c("el-input", {
                            staticClass: "input-width",
                            attrs: {
                              maxlength: _vm.maxlength,
                              "show-word-limit": "",
                              placeholder: "请输入记录标题"
                            },
                            model: {
                              value: item.title,
                              callback: function($$v) {
                                _vm.$set(item, "title", $$v)
                              },
                              expression: "item.title"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "记录描述",
                            prop: "falconPlanLangs." + index + ".content",
                            "label-width": _vm.formLabelWidth,
                            rules: _vm.rules.content
                          }
                        },
                        [
                          _c("el-input", {
                            staticClass: "input-width",
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 2, maxRows: 4 },
                              maxlength: _vm.maxlengthTextarea,
                              "show-word-limit": "",
                              placeholder: "请输入记录描述"
                            },
                            model: {
                              value: item.content,
                              callback: function($$v) {
                                _vm.$set(item, "content", $$v)
                              },
                              expression: "item.content"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.onSubmit }
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.closeDialog(false)
                }
              }
            },
            [_vm._v("取 消")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }