var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "formItem", attrs: { model: _vm.formItem } },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c(
                "el-tab-pane",
                {
                  attrs: { label: "猎鹰计划优秀案例（标题）", name: "topTitle" }
                },
                _vm._l(_vm.formItem.topTitle, function(item, index) {
                  return _c(
                    "el-form-item",
                    {
                      key: index,
                      attrs: {
                        label: item.name,
                        "label-width": _vm.formLabelWidth
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "input-width",
                        attrs: {
                          maxlength: _vm.maxlength,
                          "show-word-limit": "",
                          placeholder: "请输入标题"
                        },
                        model: {
                          value: item.content,
                          callback: function($$v) {
                            _vm.$set(item, "content", $$v)
                          },
                          expression: "item.content"
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: "猎鹰计划优秀案例（描述）",
                    name: "topDetail"
                  }
                },
                _vm._l(_vm.formItem.topDetail, function(item, index) {
                  return _c(
                    "el-form-item",
                    {
                      key: index,
                      attrs: {
                        label: item.name,
                        "label-width": _vm.formLabelWidth
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "input-width",
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 2, maxRows: 4 },
                          maxlength: _vm.maxlengthTextarea,
                          "show-word-limit": "",
                          placeholder: "请输入描述"
                        },
                        model: {
                          value: item.content,
                          callback: function($$v) {
                            _vm.$set(item, "content", $$v)
                          },
                          expression: "item.content"
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: "猎鹰计划改造记录（标题）",
                    name: "recordTitle"
                  }
                },
                _vm._l(_vm.formItem.recordTitle, function(item, index) {
                  return _c(
                    "el-form-item",
                    {
                      key: index,
                      attrs: {
                        label: item.name,
                        "label-width": _vm.formLabelWidth
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "input-width",
                        attrs: {
                          maxlength: _vm.maxlength,
                          "show-word-limit": "",
                          placeholder: "请输入标题"
                        },
                        model: {
                          value: item.content,
                          callback: function($$v) {
                            _vm.$set(item, "content", $$v)
                          },
                          expression: "item.content"
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.onSubmit }
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.closeDialog()
                }
              }
            },
            [_vm._v("取 消")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }