<template>
    <div>
        <el-form :model='formItem' :rules='rules' ref='formItem'>

            <el-form-item prop="recordDate" label="记录日期" :label-width="formLabelWidth">
                    <el-date-picker v-model="formItem.recordDate"
                        type="date"
                        placeholder="请选择记录日期"    
                        format="yyyy-MM-dd" 
                        value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
            </el-form-item>


            <el-form-item label='上传图片' class="must" :label-width='formLabelWidth'>
                <!-- <div>（不超过9张，推荐分辨率 1600*952，单张≤3MB）</div>
                <multipleImgUpload ref="multipleImgUpload" :imgSize="3" :imglimit="9" :imgUrlList="formItem.imageList"  > </multipleImgUpload> -->
                <multipleImgUploadButton ref="multipleImgUpload" :imgMessage="imgMessage" :imgSize="3" :imglimit="9" :imgUrlList="formItem.imageList"  > </multipleImgUploadButton>
            </el-form-item>

             

 

            <el-form-item label="启用状态" prop='status' :label-width='formLabelWidth'>
                <el-switch v-model="formItem.status"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                >
                </el-switch>
            </el-form-item>

            <el-form-item label="优秀案例" prop='top' :label-width='formLabelWidth'>
                <el-switch
                    @change="changeTop()"
                    v-model="formItem.top"  
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                >
                </el-switch>
            </el-form-item>
 
 
            <el-form-item v-if="coverVisible" label='上传封面' class="must"  :label-width='formLabelWidth'>
                <div>（仅限1张，推荐分辨率 1600*952，单张≤3MB）</div>        
                <imgUpload ref="imageUpload" :imgSize="3" :img="formItem.cover" @event="changeUrl"> </imgUpload>
            </el-form-item>


            
            <el-form-item label="多语言配置"   >
                <el-tabs v-model='activeName'  >
                    <el-tab-pane v-for='(item, index) in formItem.falconPlanLangs' :key='index' :label='item.name'
                                :name= "index+''"   >
                        <el-form-item label="记录标题" :prop="'falconPlanLangs.'+ index +'.title'"   :label-width='formLabelWidth' :rules="rules.title">
                            <el-input v-model="item.title" :maxlength="maxlength" show-word-limit class='input-width' placeholder='请输入记录标题'></el-input>
                        </el-form-item>
                        <el-form-item label="记录描述" :prop="'falconPlanLangs.'+ index +'.content'"  :label-width='formLabelWidth' :rules="rules.content">
                            <el-input v-model="item.content" type="textarea" :autosize="{ minRows: 2, maxRows: 4}" :maxlength="maxlengthTextarea" show-word-limit class='input-width' placeholder='请输入记录描述'></el-input>
                        </el-form-item> 
                    </el-tab-pane>
                </el-tabs>

            </el-form-item>
           
        </el-form>

 
        <div  slot="footer" class='dialog-footer'>
            <el-button   type='primary'  :loading="loading" @click='onSubmit'>确 定</el-button>
            <el-button   @click='closeDialog(false)'>取 消</el-button>     
        </div>

       
    </div>
</template>
<script>
 
 

import multipleImgUpload from '@/components/common/multipleImgUpload';
import multipleImgUploadButton from '@/components/common/multipleImgUploadButton';
import imgUpload from '@/components/common/imgUpload';
import moment from 'moment';


export default {
    components: {
        multipleImgUpload,
        multipleImgUploadButton,
        imgUpload,
        moment
    },
    props: {
        formItem: {
            type: Object,
            default: () => {
                return {};
            }
        },
        langs: {
            type: Array,
            default: () => {
                return [];
            }
        },
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    filters: {
    },
    data() {
        return {
            mailSet:[],
            rules: {
                recordDate: [
                    {
                        required: true, message: '请选择记录日期', trigger: 'change'
                    }
                ],
                title:[
                    {
                        required: true,
                        message: '请填写标题',
                        trigger: 'blur'
                    },
                    {
                        min: 1,
                        max: 200,
                        message: '长度在 1 到 200 个字符',
                        trigger: 'blur'
                    }
                ],
                content:[
                    {
                        required: true,
                        message: '请填写描述',
                        trigger: 'blur'
                    },
                    {
                        min: 1,
                        max: 1000,
                        message: '长度在 1 到 1000 个字符',
                        trigger: 'blur'
                    }
                ],
            },
            maxlength: '200',
            maxlengthTextarea: '1000',
            loading: false,
            coverVisible: false,
            imageUrl: '',
            imgUrlList: [],
            activeName: '0',
            imgMessage:'（不超过9张，推荐分辨率 1600*952，单张≤3MB）',
            languageList: JSON.parse(JSON.stringify(this.langs))  ,
            formLabelWidth: '120px',
        };
    },
    mounted() {
         this.init();
    },
    methods: {
        init(){
            if(this.formItem.top){
                this.coverVisible =true;
            }
        },
        changeTop(){
            if(this.formItem.top){
                this.coverVisible =true;
                this.formItem.cover ='';
            }else{
                this.coverVisible =false;
                this.formItem.cover ='';
            }
        },
        handleChange(){

        },
        onSubmit() {

            if( this.formItem.imageList==null ||  this.formItem.imageList.length<1 ){
                this.$message({
                    showClose: false,
                    message: '请上传图片',
                    type: 'warning'
                });
                return;
            }
            if( this.formItem.imageList!=null &&  this.formItem.imageList.length> 9 ){
                this.$message({
                    showClose: false,
                    message: '最多上传9张图片',
                    type: 'warning'
                });
                return;
            }
            if(this.formItem.top && this.formItem.cover == '' ){
                this.$message({
                    showClose: false,
                    message: '请上传封面',
                    type: 'warning'
                });
                return;
            }

            this.formItem.images = this.formItem.imageList.join(',')
            
            console.log('formItem',this.formItem)
            this.$refs['formItem'].validate((valid,Object) => {
                if (valid) {
                    // this.formItem.recordDate = moment(this.formItem.recordDate).unix() * 1000;
                    this.$confirm( '是否提交数据', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {
                        this.loading=true                        
                        if (this.isEdit) {
                          
                            this.$store.dispatch('putFalconPlan', this.formItem).then((res) => {
                                this.$message({
                                    message: '提交成功',
                                    type: 'success',
                                    duration: 1000
                                });
                                this.closeDialog(true,this.isEdit);
                            }).finally(() => {
                                this.loading = false;
                            });
                        } else {
                            this.$store.dispatch('addFalconPlan', this.formItem).then((res) => {
                                this.$message({
                                    message: '提交成功',
                                    type: 'success',
                                    duration: 1000
                                });
                                this.closeDialog(true,this.isEdit);
                            }).finally(() => {
                                this.loading = false;
                            });

                        }
                    }).catch(() => {
                    });
                } else {
                   
                    for(let item  in  Object ){
                        if(item.includes('falconPlanLangs.')){
                            let itemArr =  item.split('.');
                            if(itemArr.length > 1){
                                this.activeName = itemArr[1];
                                break
                            }
                        }
                    }
                    this.$message({
                        message: '验证失败，请先输入必填项',
                        type: 'error',
                        duration: 1000
                    });
                    return false;
                }
            });

        },
        /**
         * 上传路径
         */
         changeUrl(data) {
            this.formItem.cover = data;
        },
        
        closeDialog(status) {
            this.$emit('closeDialog',status,this.isEdit);
        },

 
    }
};
</script>
<style scoped>

 

</style>
<style>
.must .el-form-item__label:before {
    content: '*';
    color: #f56c6c;
    margin-right: 4px;
}
</style>