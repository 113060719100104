var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          ref: "uploadImg",
          staticClass: "avatar-uploader",
          attrs: {
            action: _vm.ossInfo.ossSignature.host,
            "list-type": "picture-card",
            "auto-upload": false,
            "show-file-list": true,
            accept: ".jpg, .jpeg, .png, .gif",
            "file-list": _vm.fileList,
            data: _vm.fileDataObj,
            limit: _vm.imglimit,
            "on-change": _vm.handleChange
          },
          scopedSlots: _vm._u([
            {
              key: "file",
              fn: function(ref) {
                var file = ref.file
                return _c("div", {}, [
                  _c("img", {
                    staticClass: "el-upload-list__item-thumbnail img-center",
                    attrs: { src: file.url, alt: "" }
                  }),
                  _c("span", { staticClass: "el-upload-list__item-actions" }, [
                    _c(
                      "span",
                      {
                        staticClass: "el-upload-list__item-preview",
                        on: {
                          click: function($event) {
                            return _vm.handlePictureCardPreview(file)
                          }
                        }
                      },
                      [_c("i", { staticClass: "el-icon-zoom-in" })]
                    ),
                    !_vm.disabled
                      ? _c(
                          "span",
                          {
                            staticClass: "el-upload-list__item-delete",
                            on: {
                              click: function($event) {
                                return _vm.handleDownload(file)
                              }
                            }
                          },
                          [_c("i", { staticClass: "el-icon-download" })]
                        )
                      : _vm._e(),
                    !_vm.disabled
                      ? _c(
                          "span",
                          {
                            staticClass: "el-upload-list__item-delete",
                            on: {
                              click: function($event) {
                                return _vm.handleRemove(file)
                              }
                            }
                          },
                          [_c("i", { staticClass: "el-icon-delete" })]
                        )
                      : _vm._e()
                  ])
                ])
              }
            }
          ])
        },
        [
          _c("i", {
            staticClass: "el-icon-plus",
            attrs: { slot: "default" },
            slot: "default"
          })
        ]
      ),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: { visible: _vm.dialogVisible },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [
              _c("img", {
                attrs: { width: "90%", src: _vm.dialogImageUrl, alt: "" }
              })
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }