<template>
    <div>
        <el-table ref="list" v-loading="loading" :data="list"  border  style="width: 100%;" height="500">

            <el-table-column prop="falconPlanId" label="记录ID" align="center" width='100' show-overflow-tooltip></el-table-column>
            <el-table-column prop="title" label="标题" align="center" show-overflow-tooltip></el-table-column>
 

            <el-table-column prop="cover" label="封面" align="center" width='200'  >
                <template slot-scope="scope">
                    <el-image style="width: 100px; height: 59.5px" :src="ossHost + scope.row.cover" fit="fit"></el-image>
                </template>
            </el-table-column>

            <el-table-column prop="sort" label="排序" align="center" width='200'>
                <template slot-scope="scope">
                    <el-input-number v-model="scope.row.sort" :min="0" :max="10000" class='input-width'></el-input-number>
                </template>
            </el-table-column>

  
        </el-table>


 
        <div  slot="footer" class='dialog-footer'>
            <el-button type='primary'  :loading="loading" @click='onSubmit'>确 定</el-button>
            <el-button @click='closeDialog()'>取 消</el-button>     
        </div>

       
    </div>
</template>
<script>
 
 
 

export default {
    components: {
 
    },
    props: {
 
    },
    filters: {
 
    },
    data() {
        return {
            formLabelWidth: '120px',
            loading: false,
            list:[],
            ossHost: process.env.VUE_APP_OSS_Head,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init(){
            this.loading=true
            this.$store.dispatch('getFalconPlanTopSort', {}).then((res) => {
                this.list = res;  
            }).finally(() => {
                this.loading = false;
            });
        },
        onSubmit() {
            this.$confirm( '是否提交数据', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.loading=true                        
                this.$store.dispatch('putFalconPlanTopSort', this.list).then((res) => {
                    this.$message({
                        message: '提交成功',
                        type: 'success',
                        duration: 1000
                    });
                    this.closeDialog();
                }).finally(() => {
                    this.loading = false;
                });
                
            }).catch(() => {
            });
        },
        closeDialog() {
            this.$emit('closeTopSortDialog');
        },
    }
};
</script>
<style scoped>


.dialog-footer {
 
    margin-top: 30px;
}
</style>
